.uploadDoc {
  background-image: url("../../../assets/images/basic.png");
  padding: 80px 0;
  /* background: #000000; */
  background-color: #ccf1e5;
  background-repeat: no-repeat;
  background-size: cover;
  /* padding: 20px 20px; */
  padding: 150px;
}

.uploadDoc h3 {
  text-align: center;
  margin-bottom: 40px;
}

.outerUpoad {
  max-width: 750px;
  margin: 0 auto;
}

.innerUpload {
  /* background: #14222D;
    padding: 31px;
    border-radius: 10px; */
  /* background: #000; */
  background: #fff;
  padding: 31px;
  border-radius: 10px;
  /* color: #fff; */
  color: #000;
}

.innerUpload h5 {
  font-size: 20px;
  color: #000;
  margin: 0 0 13px;
}

.innerUpload h6 {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 13px;
  margin: 23px 0 18px;
}

.innerUpload p {
  margin: 0;
  font-size: 12px;
  color: #000;
  opacity: 0.6;
}

.uploadFrame {
  position: relative;
  width: 100%;
  height: 140px;
  margin-top: 15px;
}

.uploadFrame input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  font-size: 0;
  padding: 30px;
}

.uploadFrame span {
  background: #fff;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #a3bd61;
  color: #a3bd61;
  font-size: 25px;
  border-radius: 9px;
  pointer-events: none;
}

span.tagName {
  text-align: center;
  color: #000;
  width: 100%;
  display: table;
  margin-top: 11px;
  font-size: 15px;
}

.uploadFrame img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 10px;
  object-fit: cover;
}

.submit {
  background-image: linear-gradient(to right, #2ca8ef, #03fe9d);
  /* background: #03FE9D;
    color: #000; */
  background: #a3bd61;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 0;
  border-radius: 5px;
  padding: 12px 0;
  padding: 10px 15px;
  margin-top: 16px;
}

.error {
  color: red;
  font-size: 13px;
  margin-bottom: 0px;
  margin-left: 5px;
  margin-top: 3px;
  text-align: center;
}
@media (max-width: 991px) {
  .uploadDoc {
    padding: 110px 17px 50px;
  }
}
@media (max-width: 767px) {
  .uploadDoc {
    padding: 91px 4px 30px;
  }
  .innerUpload {
    margin: 10px 0;
  }
  .uploadDoc h3 {
    margin-bottom: 10px;
  }
}
