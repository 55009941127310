.bassoDtailFoem {
  background-image: url("../../../assets/images/basic.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #ccf1e5;
  padding: 20px 20px;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}
.baseBnner.bassoDtailFoem {
  height: auto;
  padding: 85px 0;

}
.bassoDtailFoem::before {
  position: absolute;
  background: #00000099;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
}
.bassoDtailFoem form {
  background: #fff;
  max-width: 550px;
  margin: 0 auto;
  padding: 30px;
  padding: 29px 30px 20px;
  position: relative;
  z-index: 9;
  border-radius: 20px;
}
.gormLogo img {
  max-width: 118px;
  margin: 0 auto;
  display: table;
}
.bassoDtailFoem form h4 {
  font-size: 28px;
  color: #000;
  text-align: center;
  font-weight: 600;
  margin: 25px 0 33px;
}
.bassoDtailFoem form p {
  color: #000 !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  margin: 9px 0 8px;
  opacity: 1;
}
.bassoDtailFoem .formGroup label {
  color: #000;
  font-size: 15px;
  width: 100%;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.formGroup input {
  height: 48px;
  border-radius: 12px;
  border: 1px solid #e4e4e4;
  background: transparent;
  padding: 0 12px;
  font-size: 15px;
  width: 100%;
  color: #707070;
}
.formGroup select {
  height: 48px;
  border-radius: 12px;
  border: 1px solid #e4e4e4;
  background: transparent;
  padding: 0 12px;
  font-size: 15px;
  width: 100%;
  appearance: none;
  color: #707070;
}
.bassoDtailFoem .formGroup {
  margin-bottom: 20px;
  position: relative;
}
.bassoDtailFoem .formGroup svg {
  fill: #707070;
  color: #707070;
}
.bassoDtailFoem .formGroup input[type="submit"] {
  /* background: #03FE9D; */
  background: #a3bd61;
  /* color: #000; */
  color: #fff;
  font-weight: 600;
  margin-top: 12px;
}
.bassoDtailFoem .formGroup span {
  position: absolute;
  top: 37px;
  right: 15px;
  pointer-events: none;
}
.phoneNumber input {
  border: 1px solid #e4e4e4;
  width: 100%;
  background: transparent;
  border-radius: 10px;
  height: 48px;
  font-weight: 400;
  padding: 0 21px;
  font-size: 16px;
  color: #707070;
  outline: none;
}
.error {
  color: red;
  font-size: 13px;
  margin-bottom: 0px;
  margin-left: 5px;
  font-weight: 600;
}
.getStartBk {
  background: #fff;
  max-width: 550px;
  margin: 0 auto;
  border-radius: 10px;
  padding: 30px 40px 40px;
  width: 100%;
  z-index: 9;
  background-image: url("../../../assets/images/boxbk.png");
}
.stepHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}
.stepHeader img {
  max-width: 120px;
}
.stepMain img {
  margin: 0 auto;
  display: table;
}
.stepMain h3 {
  font-size: 35px;
  font-weight: 700;
  text-align: center;
  margin: 22px 0 10px;
}
.stepMain p b {
  width: 100%;
  display: table;
}
.stepMain p {
  color: #000000;
  font-size: 19px;
  text-align: center;
  line-height: 26px;
}
.stepMain button {
  background: #a3bd61;
  padding: 12px 94px;
  border: 0;
  border-radius: 10px;
  color: #fff;
  margin: 0 auto;
  display: table;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.stepMain button i {
  font-size: 11px;
  margin-left: 8px;
}
.stepMain.stepTwo h3 {
  font-size: 30px;
}
.stepMain.stepTwo .formFroup {
  margin: 13px 0 20px;
  position: relative;
}
.stepMain.stepTwo .formFroup label {
  font-size: 15px;
  margin-bottom: 2px;
}
.stepMain.stepTwo .formFroup select {
  height: 48px;
  border: 1px solid #e4e4e4;
  width: 100%;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 400;
  color: #00000078;
  padding: 0 13px;
  appearance: none;
}

.stepMain.stepTwo .formFroup span {
  position: absolute;
  right: 12px;
  top: 41px;
}
@media (max-width: 1300px) {
  .bassoDtailFoem {
    padding: 120px 20px 20px;
  }
}

@media (max-width: 767px) {
  .bassoDtailFoem form {
    padding: 30px 20px;
  }
  .bassoDtailFoem {
    padding: 87px 15px 82px;
    height: auto;
    background-size: cover;
  }
  .baseBnner.bassoDtailFoem {
    height: auto;
    padding: 47px 15px 42px;
  }
  .getStartBk {
    background: #fff;
    max-width: 550px;
    margin: 0 auto;
    border-radius: 10px;
    padding: 20px 16px 23px;
    width: 100%;
    z-index: 9;
  }
  .stepHeader img {
    max-width: 82px;
  }
  .stepMain img {
    width: 100%;
  }
  .stepMain h3 {
    font-size: 26px;
    font-weight: 700;
  }
  .stepMain p {
    font-size: 16px;
    line-height: 26px;
  }
  .stepMain.stepTwo h3 br {
    display: none;
  }
  .stepMain.stepTwo h3 {
    font-size: 25px;
  }
  .stepMain.stepTwo .formFroup label {
    font-size: 12px;
    margin-bottom: 2px;
  }
  .stepMain.stepTwo .formFroup select {
    font-size: 14px;
  }
  .stepMain.stepTwo .formFroup span {
    top: 39px;
  }
  .bassoDtailFoem form h4 {
    font-size: 19px;
  }
}
